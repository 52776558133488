@import '../../style/mixins.scss';

.filter {
    margin: 25px auto;
    display: flex;
    justify-content: center;
    gap: 25px;

    @include mobile-only {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: fit-content;
        }

    .btn-filter {
        padding: 10px;
        border: none;
        background: grey;
        border-radius: 5px;
        color: white;
        font-size: 22px;
        opacity: 0.5;
        transition: all 0.5s ease-in-out;

        

        &:hover {
            cursor: pointer;
            opacity: 1;
            transition: all 0.5s ease-in-out;
        }
    }

    .active {
        opacity: 1;
        transition: all 0.5s ease-in-out;
    }
}