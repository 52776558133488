@import '../../style/mixins.scss';

.formation {
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  width: 80%;

  @include mobile-only {
    margin: 50px 0;
    width: 100%;
  }

  .formation-title {
    text-align: center;
    color: white;
    font-size: 38px;
    font-weight: 600;
    width: 20%;
    margin: auto;
    padding-bottom: 15px;

    @include mobile-only {
      font-size: 30px;
      width: 50%;
      font-weight: 500;
    }

    @include tablet-only {
      width: 30%;
    }
  }
}

.school-title,
.experience-title {
  display: flex;
  color: white;
  font-size: 28px;
  font-weight: 400;
  justify-content: flex-start;

  @include mobile-only {
    font-size: 22px;
    justify-content: center;
  }

  @include large-screen {
    justify-content: center;
  }
}

.school-list,
.experience-list {
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding-left: 15px;
  width: 55%;
  margin-left: 50px;

  @include mobile-only {
    width: 100%;
    gap: 0;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  @include tablet-only {
    width: 75%;
  }

  @include large-screen {
    margin: 20px auto;
  }
}

.school,
.experience {
  margin: 35px;

  @include mobile-only {
    margin: 0;
  }
}

.project {
  display: grid;
  grid-template-columns: repeat(2, 500px);
  gap: 50px;
  margin-top: 50px;
  justify-content: center;

  @include mobile-only {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: auto;
    gap: 15px;
    margin-top: 20px;
  }

  @include tablet-only {
    gap: 20px;
    width: auto;
    grid-template-columns: repeat(2, auto);
  }

  @include large-screen {
    margin: 50px auto;
    width: 70%;
  }
}

#projets {
  margin: 150px auto;

  @include mobile-only {
    margin: 0;
  }

  .project-title {
    text-align: center;
    color: white;
    font-size: 38px;
    font-weight: 600;
    margin: auto;
    width: 20%;

    @include mobile-only {
      width: auto;
      font-size: 30px;
    }

    @include tablet-only {
      width: auto;
    }
  }
}
