@import '../../style/mixins.scss';

header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0 1px 1px  rgba(250, 250, 250, 0.1);
    background-color: #171c28;
    transition: transform 0.3s ease-in-out;

    @include mobile-only {
        max-width: 768px;
        position: static;
        padding: 10px 5px;
        width: auto;
    }
}

.header-contain {
    display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;

    @include mobile-only {
        margin: 0;
        max-width: 768px;
        padding:0 5px;
    }

    .header-name {
        font-size: 30px;
        color: white;
        font-family: 'Dancing Script', cursive;
        font-weight: 500;

        @include mobile-only {
            font-size: 18px;
            text-align: center;
        }
    }

    .header-nav {
        
        ul {
            display: flex;
            gap: 25px;
            margin: 0;

            @include mobile-only {
                gap: 15px;
                padding-left: 5px;
                flex-direction: column;
                background-color: #303545;
                
            }
            
            li {
                list-style-type: none;

                @include mobile-only {
                    font-size: 20px;
                    //padding-right: 15px;
                }

                .header-nav-link {
                    color: white;
                    text-decoration: none;
                    opacity: 0.5;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        opacity: 1;
                        transition: all 0.5s ease-in-out;
                    }
                }
            }
        }
    }
}

.header-nav-appear {
    height: 235px;
    transform: translateX(0);
    transition: all 1s ease-in-out;
    position: absolute;
    z-index: 10;
    top: 55px;
    overflow: hidden;
    right: 0;
}

.header-nav-disappear {
    position: absolute;
    z-index: 10;
    top: 50px;
    height: 0;
    overflow: hidden;
    transform: translateX(100%);
    transition: all 1s ease-in-out;
    right: 0;
}

.header-btn {
    border: none;
    background: none;
    padding: 5px;
    fill: white;
    display: none;

    @include mobile-only {
        display: flex;
    }

    svg {
        height: 25px;
    }
}

.header-hidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}
