@import '../../style/mixins.scss';

.skills {
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    height: max-content;

    .skill-title {
        text-align: center;
        color: white;
        font-size: 38px;
        font-weight: 600;
        margin: auto;
        width: 40%;

        @include mobile-only {
            width: auto;
            font-size: 30px;
        }

        @include tablet-only {
            width: auto;
        }
    }

    @include mobile-only {
        margin: 50px 0;
        height: 100%;
    }

    @include tablet-only {
            height: max-content;
        }
}

.skill-bloc {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 75px;
    place-items: center;
    margin: 50px auto 0 auto;

    @include mobile-only {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: 15px;
        place-items: center;
        
        /*:nth-child(5) {
            grid-area: 3/1/4/3;
            text-align: center;
        }*/
    }

    @include tablet-only {
        display: grid;
        //grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        //gap: 0;
        place-items: center;
        height: max-content;
    }

    .skill-container {
        //padding: 5px 10px;
        //position: relative;
        //border-radius: 20px;
        display: flex;
        
        .skill-img {
            width: 100%;
            max-width: 150px;
            height: 100%;
            max-height: 150px;

            @include mobile-only {
                max-width: 75px;
                max-height: 75px;
            }
        }

        /*h3 {
            text-align: center;
            color: white;
            font-size: 26px;

            @include mobile-only {
                margin: 10px 0;
                font-size: 22px;
                font-weight: 400;
            }
        }

        svg {
            height: 150px;
            width: 150px;

            @include mobile-only {
                height: 100px;
                width: 100px;
            }

            circle {
                fill: none;
                stroke: firebrick; //bordure du cercle
                stroke-width: 10px; //largeur de la bordure
                transform: translate(5px, 5px);
                stroke-dasharray: 440; //motif et espace des traits
                stroke-dashoffset: 440; //position de départ des pointillés sur les lignes SVG
                stroke-linecap: round; //bout du trait dans le cercle
                
                @include mobile-only {
                    cx: 40px;
                    cy: 40px;
                    r: 40px;
                    stroke-dasharray: 250px;
                    stroke-dashoffset: 250px;
                    stroke-width: 6px;
                }
            }

            .circle-regular {
                stroke-dashoffset: 0;
                stroke: grey;
            }
        }

        span {
            position: absolute;
            left: 35%;
            bottom: 30%;
            font-size: 28px;
            font-weight: 700;
            color: white;

            @include mobile-only {
                font-size: 18px;
                font-weight: 400;
                bottom: 33%;
            }

            @include tablet-only {
                font-size: 24px;
                bottom: 30%;
                left: 37%;
            }
        }
    }*/
}
/*
.html, .css {
    --offset: 44;

    @include mobile-only {
        --offset : 25;
    }
}

.sass, .git, .js {
    --offset: 176;

    @include mobile-only {
        --offset: 100;
    }
}

.react, .github {
    --offset: 132;

    @include mobile-only {
        --offset : 75;
    }
}

.redux {
    --offset: 264;

    @include mobile-only {
        --offset: 150;
    }
}

.circle-animation {
    animation: circle-animation 2s ease-in-out forwards;
}

@keyframes circle-animation {
    to {
        stroke-dashoffset: var(--offset);
    }*/
}