@import '../../style/mixins.scss';

.school-elmt-contain {
    display: flex;
    gap: 50px;
    border-bottom: solid 2px white;
    padding: 10px 20px 30px 20px;
    opacity: 0;
    transform: translateX(-50%);

    @include mobile-only {
        animation: none;
        opacity: 0;
        width: 70%;
        transform: translateX(0);
        margin-left: 15px;
    }
    
    .school-img {
        display: flex;
        width: 100%;
        height: 100%;
        max-width: 100px;
        max-height: 100px;
        object-fit: contain;
        border-radius: 15px;
        align-self: center;

        @include mobile-only {
            max-width: 75px;
        }
    }

    .school-elmt {
    list-style: none;
    padding: 10px;
    flex: 1;

    span {
        color: white;
        border-radius: 5px;
        font-size: 22px;

        @include mobile-only {
            font-size: 18px;
        }
    }

    p {
        color: white;
        margin: 10px 0;

        @include mobile-only {
            font-size: 14px;
        }
    }
        }
}

.school-name {
    font-size: 18px;
}

.school-location {
    font-size: 16px;
}

.visible {
    animation: appear 1s ease-in-out forwards;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

