@import '../../style/mixins.scss';

.presentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto;
  height: max-content;

  @include mobile-only {
    margin: 0;
    height: max-content;
  }

  .presentation-title {
    color: white;
    font-size: 38px;
    font-weight: 500;

    @include mobile-only {
      font-size: 32px;
      font-weight: 400;
    }
  }

  .presentation-content {
    display: flex;
    width: 70%;
    gap: 50px;

    @include mobile-only {
      flex-direction: column;
      gap: 0;
      align-items: center;
    }

    .presentation-description {
      color: white;

      @include mobile-only {
        text-align: center;
      }

      h3 {
        font-size: 32px;
        margin-left: 10%;

        @include mobile-only {
          font-size: 26px;
          font-weight: 400;
          margin-left: 0;
        }
      }

      p {
        font-size: 24px;

        @include mobile-only {
          font-size: 20px;
        }
      }
    }
  }
}
