@import '../../style/mixins.scss';

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 15px auto;
  margin-top: 80px;
  height: 600px;

  @include mobile-only {
    margin: 0;
    margin-top: 80px;
    height: max-content;
    flex-direction: column;
    gap: 10px;
  }

  @include tablet-only {
    height: max-content;
  }

  .banner-img {
    display: flex;
    justify-content: center;
    margin: 20px;

    .presentation-img {
      width: 100%;
      height: 100%;
      max-width: 300px;
      object-fit: cover;
      border: 5px solid white;

      @include mobile-only {
        max-width: 150px;
        border: 1px solid white;
      }

      @include tablet-only {
        max-width: 250px;
        align-self: center;
      }
    }
  }

  .banner-content {

    @include mobile-only {
        text-align: center;
    }

    .banner-title {
    color: white;
    font-size: 52px;
    font-weight: 700;
    margin: 10px;

    @include mobile-only {
      font-size: 36px;
      font-weight: 500;
    }

    @include tablet-only {
      font-size: 42px;
    }
  }

  .banner-description {
    color: white;
    font-size: 46px;
    font-weight: 400;
    margin: 10px;

    @include mobile-only {
      font-size: 28px;
      font-weight: 200;
      text-align: center;
    }

    @include tablet-only {
      font-size: 32px;
    }
  }
}

  }

  