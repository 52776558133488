@import '../../style/mixins.scss';

footer {
  background: #333;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  padding: 20px 10px;

  @include mobile-only {
    padding: 20px 0;
  }

  .footer-span {
    color: whitesmoke;
  }

  ul {
    display: flex;
    gap: 30px;
    padding: 0;

    li {
      list-style-type: none;

      a {
        &:hover {
          cursor: pointer;
        }

        svg {
          width: 100%;
          height: 100%;
          max-height: 30px;
          fill: whitesmoke;
        }
      }
    }
  }
}
