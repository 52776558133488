@import '../../style/mixins.scss';

#contact {
  @include mobile-only {
    margin-top: 25px;
  }
}

.contact-title {
  text-align: center;
  color: white;
  font-size: 32px;

  @include mobile-only {
    font-size: 26px;
  }
}

.contact-form {
  margin: 0 auto;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .input-container {
    display: flex;
    flex-direction: column;
    width: 25%;

    @include mobile-only {
      width: 50%;
    }

    label {
      color: white;
      font-size: 22px;

      @include mobile-only {
        font-size: 18px;
        text-align: center;
      }
    }

    input,
    textarea {
      border: none;
      border-radius: 5px;
      margin: 5px;
    }

    input {
      height: 30px;
      font-size: 18px;
    }

    textarea {
      font-size: 16px;
    }
  }
}

.btn-submit {
  padding: 10px;
  border: none;
  background: grey;
  border-radius: 5px;
  color: white;
  font-size: 22px;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
}

.validation-message {
  color: black;
  font-size: 26px;
  margin: 0;
  position: fixed;
  top: 50%;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border: solid;
  z-index: 10;
  display: block;
  animation: disapear 1s ease-in-out forwards;
  animation-delay: 3s;

  @include mobile-only {
    font-size: 20px;
  }
}

@keyframes disapear {
  to {
    display: none;
  }
}
