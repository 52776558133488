@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@300;400;500;600;700;800&display=swap');
@import '../style/mixins.scss';

html {
    scroll-behavior: smooth;

    @include mobile-only {
        scroll-behavior: auto;
    }
}

body {
    margin: 0 auto;
    font-family: 'Dosis', sans-serif;
    background-color: #171c28;
    max-width: 1920px;

    @include mobile-only {
        margin: 0;
        max-width: 768px;
    }

    @include large-screen {
        max-width: none;
    }
    }