@import '../../style/mixins.scss';

.project-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #303545;
  padding: 10px;
  border-radius: 25px;
  position: relative;

  @include tablet-only {
    text-align: center;
  }

  @include mobile-only {
    background-color: #171c28;
  }
}

.project-item-title {
  color: white;
  font-size: 26px;
  font-weight: 600;
  margin: 0 0 10px 0;

  @include mobile-only {
    font-size: 22px;
    font-weight: 500;
  }

  @include tablet-only {
    text-align: center;
    font-size: 24px;
  }
}

.project-item-contain {
  display: flex;
  flex-direction: column;
  place-items: center;

  a {
    &:hover {
      cursor: pointer;
    }

    img {
      object-fit: cover;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      max-width: 500px;

      @include mobile-only {
        max-width: 300px;
      }

      @include tablet-only {
        max-width: 400px;
      }
    }
  }
}

.project-btn {
  margin: 10px 5px;
  border: none;
  background: none;
  color: white;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
}
