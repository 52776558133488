@import '../../style/mixins.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background-color: #171c28;
  padding: 20px;
  max-width: 50%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  animation: slideIn 0.5s forwards;

  .modal-title {
    color: white;
    font-size: 32px;
    text-align: center;
    margin: 0 0 10px 0;

    @include mobile-only {
      font-size: 22px;
    }
  }

  .modal-img {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  .modal-description {
    color: white;
    font-size: 22px;
    text-align: center;

    @include mobile-only {
      font-size: 14px;
    }
  }
}

.modal-contain {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile-only {
    display: flex;
    flex-direction: column;
  }

  .modal-list {
    display: flex;
    gap: 20px;
    margin: 5px 0;
    padding: 0 0 0 20px;

    @include mobile-only {
      gap: 5px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      padding: 0;
      flex: 1;
      text-align: center;
    }

    .modal-list-elmt {
      list-style-type: none;
      color: black;
      background-color: #f7f7f7;
      border-radius: 10px;
      padding: 5px;
      font-size: 16px;
    }
  }
  .modal-link {
    color: white;
    text-decoration: none;
    opacity: 0.5;
    transition: all 0.5s ease-in-out;
    display: flex;
    gap: 10px;
    align-content: center;

    &:hover {
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }

    svg {
      fill: white;
      width: 20px;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  width: 30px;
  transition: all 0.5s ease-in-out;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  svg {
    fill: white;
  }
}
